import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "./redux/blockchain/blockchainActions";
import { fetchData } from "./redux/data/dataActions";
import * as s from "./styles/globalStyles";
import styled from "styled-components";

const truncate = (input, len) =>
  input.length > len ? `${input.substring(0, len)}...` : input;

export const StyledButton = styled.button`
  padding: 10px;
  border-radius: 50px;
  border: none;
  background-color: var(--secondary);
  padding: 20px;
  font-size: 28px;
  font-weight: bold;
  letter-spacing: 3px;
  font-family: myButtonFont;
  color: var(--secondary-text);
  width: 200px;
  cursor: pointer;
  box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const StyledLore = styled.div`
  padding: 10px;
  font-size: 30px;
  font-weight: normal;
  font-family: myButtonFont;
  width: 100%;
  align-items: center;
  text-align: center;
 
`;

export const TraitWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;
export const TraitImage = styled.div`
  width: 20%;
  margin: 5px;
  align-items: center;
  text-align: center;
`;
export const StyledTraits = styled.img`
  border: 4px solid var(--secondary);
  background-color: var(--accent);
  border-radius: 25%;
  margin: 5px;
  width: 100%;
  transition: width 0.5s;
`;

export const StyledRoundButton = styled.button`
  padding: 10px;
  border-radius: 100%;
  border: none;
  background-color: var(--primary);
  padding: 10px;
  font-weight: bold;
  font-size: 15px;
  color: var(--primary-text);
  width: 30px;
  height: 30px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const ResponsiveWrapperOne = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: stretched;
  align-items: stretched;
  width: 900px;
  @media (min-width: 767px) {
    flex-direction: row;
  }
`;
export const ResponsiveWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: stretched;
  align-items: stretched;
  width: 100%;
  @media (min-width: 767px) {
    flex-direction: row;
  }
`;


export const StyledLogo = styled.img`
  width: 200px;

  @media (min-width: 767px) {
    width: 500px;
  }
  transition: width 0.5s;
  transition: height 0.5s;
`;

export const StyledRoadmap = styled.img`
  width: 1000px;
  @media (min-width: 767px) {
    width: 700px;
  }
  transition: width 0.5s;
  transition: height 0.5s;
`;
export const StyledImg = styled.img`
  box-shadow: 0px 5px 11px 2px rgba(0, 0, 0, 0.7);
  border: 4px solid var(--secondary);
  background-color: var(--accent);
  border-radius: 100%;
  width: 300px;
  align: right;
  @media (min-width: 900px) {
    width: 350px;
  }
  @media (min-width: 1000px) {
    width: 350px;
  }
  transition: width 0.5s;
`;

export const StyledImgTwo = styled.img`
  box-shadow: 0px 5px 11px 2px rgba(0, 0, 0, 0.7);
  border: 4px solid var(--secondary);
  background-color: var(--accent);
  border-radius: 100%;
  width: 150px;
  @media (min-width: 900px) {
    width: 150px;
  }
  @media (min-width: 1000px) {
    width: 200px;
  }
  transition: width 0.5s;
`;




export const StyledTwitter = styled.img`
  box-shadow: 0px 5px 11px 2px rgba(0, 0, 0, 0.7);
  border: 4px solid var(--secondary);
  border-radius: 50%;
  width: 70px;
  
`;
export const StyledLink = styled.a`
  color: var(--secondary);
  text-decoration: none;
`;

function App() {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  const [claimingNft, setClaimingNft] = useState(false);
  const [feedback, setFeedback] = useState(`Click mint to create your unique NFT.`);
  const [mintAmount, setMintAmount] = useState(1);
  const [CONFIG, SET_CONFIG] = useState({
    CONTRACT_ADDRESS: "",
    SCAN_LINK: "",
    NETWORK: {
      NAME: "",
      SYMBOL: "",
      ID: 0,
    },
    NFT_NAME: "",
    SYMBOL: "",
    MAX_SUPPLY: 1,
    WEI_COST: 0,
    DISPLAY_COST: 0,
    GAS_LIMIT: 0,
    MARKETPLACE: "",
    MARKETPLACE_LINK: "",
    SHOW_BACKGROUND: false,
  });

  const claimNFTs = () => {
    let cost = CONFIG.WEI_COST;
    let gasLimit = CONFIG.GAS_LIMIT;
    let gasPrice = CONFIG.GAS_PRICE;
    let totalCostWei = String(cost * mintAmount);
    let totalGasLimit = String(gasLimit * mintAmount);
    let totalGasPrice = String(gasPrice * mintAmount);
    console.log("Cost: ", totalCostWei);
    console.log("Gas limit: ", totalGasLimit);
    setFeedback(`Minting your ${CONFIG.NFT_NAME}...`);
    setClaimingNft(true);
    blockchain.smartContract.methods
      .mint(mintAmount)
      .send({
        gasLimit: String(totalGasLimit),
        maxPriorityFeePerGas: null,
        maxFeePerGas: null,
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: totalCostWei,
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("Sorry, something went wrong please try again later.");
        setClaimingNft(false);
      })
      .then((receipt) => {
        console.log(receipt);
        setFeedback(
          `CONGRATS, you own new ${CONFIG.NFT_NAME}! It will be viewable on Opensea.io shortly.`
        );
        setClaimingNft(false);
        dispatch(fetchData(blockchain.account));
      });
  };

  const decrementMintAmount = () => {
    let newMintAmount = mintAmount - 1;
    if (newMintAmount < 1) {
      newMintAmount = 1;
    }
    setMintAmount(newMintAmount);
  };

  const incrementMintAmount = () => {
    let newMintAmount = mintAmount + 1;
    if (newMintAmount > 50) {
      newMintAmount = 50;
    }
    setMintAmount(newMintAmount);
  };

  const getData = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account));
    }
  };

  const getConfig = async () => {
    const configResponse = await fetch("/config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const config = await configResponse.json();
    SET_CONFIG(config);
  };

  useEffect(() => {
    getConfig();
  }, []);

  useEffect(() => {
    getData();
  }, [blockchain.account]);

  return (
    <s.Screen>
      <s.Container
        flex={3}
        ai={"center"}
        style={{ padding: 24, backgroundColor: "var(--primary)" }}
        image={CONFIG.SHOW_BACKGROUND ? "/config/images/bg.png" : null}
      >
        
            <StyledLogo alt={"logo"} src={"/config/images/logo.png"} />
         
        <s.SpacerSmall />
        <ResponsiveWrapperOne flex={1} style={{ padding: 24 }}>

          <s.Container flex={1} jc={"center"} ai={"center"}>
            <StyledImg alt={"Hidden until you mint."} src={"/config/images/hidden.png"} />
          </s.Container>

         

          <s.Container
            flex={1}
            jc={"center"}
            ai={"center"}
                        
          >
            {Number(data.totalSupply) >= CONFIG.MAX_SUPPLY ? (
              <>
                <s.TextTitle
                  style={{ textAlign: "center", color: "var(--accent-text)" }}
                >
                  The dAppy Bear NFT has sold out.
                </s.TextTitle> 
                
            
               
              </>
            ) : (
              <>
              <s.TextTitle
                style={{ textAlign: "center", color: "var(--accent-text)" }}
              >
                <StyledLore>Mint a dAppy Bear</StyledLore>
              </s.TextTitle>
                
                
            
               
              </>
            )}

            <s.TextTitle
              style={{
                textAlign: "center",
                fontSize: 50,
                fontWeight: "bold",
                color: "var(--accent-text)",
              }}
            >
              {data.totalSupply} / {CONFIG.MAX_SUPPLY}
            </s.TextTitle>
           
            <s.SpacerSmall />
            {Number(data.totalSupply) >= CONFIG.MAX_SUPPLY ? (
              <>
                <s.TextTitle
                  style={{ textAlign: "center", color: "var(--accent-text)" }}
                >
                  The dAppyBears NFT has sold out.
                </s.TextTitle>
                <s.TextDescription
                  style={{ textAlign: "center", color: "var(--accent-text)" }}
                >
                  You can still find {CONFIG.NFT_NAME} on
                </s.TextDescription>
                <s.SpacerSmall />
                <StyledLink target={"_blank"} href={CONFIG.MARKETPLACE_LINK}>
                  {CONFIG.MARKETPLACE}
                </StyledLink>
              </>
            ) : (
              <>
                <s.TextTitle
                  style={{ textAlign: "center", color: "var(--accent-text)" }}
                >
                  Mint cost is {CONFIG.DISPLAY_COST}{" "}
                  {CONFIG.NETWORK.SYMBOL}.
                </s.TextTitle>
                <s.SpacerXSmall />
                <s.TextDescription
                  style={{ textAlign: "center", color: "var(--accent-text)" }}
                >
                  
                </s.TextDescription>
                <s.SpacerSmall />
                {blockchain.account === "" ||
                blockchain.smartContract === null ? (
                  <s.Container ai={"center"} jc={"center"}>
                    <s.TextDescription
                      style={{
                        textAlign: "center",
                        color: "var(--accent-text)",
                      }}
                    >
                      Connect to the {CONFIG.NETWORK.NAME} network
                    </s.TextDescription>
                    <s.SpacerSmall />
                    <StyledButton
                      onClick={(e) => {
                        e.preventDefault();
                        dispatch(connect());
                        getData();
                      }}
                    >
                      CONNECT
                    </StyledButton>
                    {blockchain.errorMsg !== "" ? (
                      <>
                        <s.SpacerSmall />
                        <s.TextDescription
                          style={{
                            textAlign: "center",
                            color: "var(--accent-text)",
                          }}
                        >
                          {blockchain.errorMsg}
                        </s.TextDescription>
                      </>
                    ) : null}
                  </s.Container>
                ) : (
                  <>
                    <s.TextDescription
                      style={{
                        textAlign: "center",
                        color: "var(--accent-text)",
                      }}
                    >
                      {feedback}
                    </s.TextDescription>
                   
                        <>
                        
                    <s.SpacerMedium />
                    <s.Container ai={"center"} jc={"center"} fd={"row"}>
                      <StyledRoundButton
                        style={{ lineHeight: 0.4 }}
                        disabled={claimingNft ? 1 : 0}
                        onClick={(e) => {
                          e.preventDefault();
                          decrementMintAmount();
                        }}
                      >
                        -
                      </StyledRoundButton>
                      <s.SpacerMedium />
                      <s.TextDescription
                        style={{
                          textAlign: "center",
                          color: "var(--accent-text)",
                        }}
                      >
                        {mintAmount}
                      </s.TextDescription>

                      <s.SpacerMedium />

                      <StyledRoundButton
                        disabled={claimingNft ? 1 : 0}
                        onClick={(e) => {
                          e.preventDefault();
                          incrementMintAmount();
                        }}
                      >
                        +
                      </StyledRoundButton>
                    </s.Container>
                    <s.SpacerSmall />
                         <s.Container ai={"center"} jc={"center"} fd={"row"}>
                      <StyledButton
                        disabled={claimingNft ? 1 : 0}
                        onClick={(e) => {
                          e.preventDefault();
                          claimNFTs();
                          getData();
                        }}
                      >
                        {claimingNft ? "MINTING" : "MINT"}
                      </StyledButton>

                      <s.SpacerSmall />
                      
                    </s.Container>
                        </>
                    
                    
                  </>
                )}
              </>
            )}
            <s.SpacerMedium />

          
          </s.Container>
          
        </ResponsiveWrapperOne>

        <s.SpacerMedium />
        <s.Container jc={"center"} ai={"center"} style={{ width: "70%" }}>
          <s.TextDescription
            style={{
              textAlign: "center",
              padding: "5px",
              color: "var(--top-text)",
            }}
          >When you mint your dAppyBears NFT it will be automatically generated and sent to your wallet.
          <s.SpacerSmall />
            Minting only works with <a href="https://metamask.io/" target="_blank" style={{ textDecoration: 'none', color: '#000' }}>Metamask</a>. <a href="https://docs.polygon.technology/docs/develop/metamask/config-polygon-on-metamask/" target="_blank" style={{ textDecoration: 'none', color: '#000' }}>Learn how to add the Polygon network to your wallet</a>. 
          </s.TextDescription>
          <s.SpacerSmall />
       
        </s.Container>

        <s.SpacerMedium />
            <TraitWrapper>
              <StyledLore>If your dAppy Bears NFT has these traits it's very rare.</StyledLore>
            </TraitWrapper>
            <TraitWrapper>
              <s.SpacerSmall />
              <TraitImage>
              <StyledTraits alt={"preview"} src={"/config/images/gas-masks-1.jpg"} />
              
              Gas Masks
              </TraitImage>
            
              <TraitImage>
                <StyledTraits alt={"preview"} src={"/config/images/lightBG.jpg"} />
              
                Pastel BGs
              </TraitImage>
           
              <TraitImage>
                <StyledTraits alt={"preview"} src={"/config/images/thebomb.jpg"} />
              
                Brain Bomb
              </TraitImage>
            
              <TraitImage>
                <StyledTraits alt={"preview"} src={"/config/images/zombie-9.jpg"} />
              
                Zombies
              </TraitImage>
            
              <TraitImage>
                <StyledTraits alt={"preview"} src={"/config/images/diamond-hands.jpg"} />
              
                Diamond Hands
              </TraitImage>
            
            </TraitWrapper>

        </s.Container>

        
    <ResponsiveWrapper flex={1} style={{ padding: 24 }}>
      

            <s.Container flex={1} jc={"center"} ai={"center"}>

              <StyledImg alt={"preview"} src={"/config/images/preview_2.jpg"} />

            </s.Container>

            
            <s.SpacerLarge />
            <s.Container
              flex={2}
              jc={"left"}
              ai={"left"}
              style={{
                padding: 24,
                color: "#ffffff",
              }}
            >

                <s.TextTitle
                  style={{
                    textAlign: "left",
                    fontSize: 40,
                    fontWeight: "bold",
                    color: "var(--primary-text)",
                  }}
                >
                  10,000 Unique dAppy Bears
                </s.TextTitle>
              <s.TextDescription
                  style={{
                    textAlign: "left",
                    backgroundColor: "#000000",
                    padding: "5px",
                    color: "var(--primary-text)",
                    width: "100%",
                  }}
                >
                   The dAppy Bears is a Collection of 10,000 generative NFTs with over 200 dAppy properties for backgrounds, bodies, eyes, mouths, shirts, jewelry and hats. <strong>Look for the ultra rare zombie properties</strong>.
                   <s.SpacerSmall />
                   Hold a dAppy Bear NFT in your wallet and you will have access to The Sandbox game experience located at <a href="https://www.sandbox.game/en/map/?liteMap=false&currentX=1323&currentY=3307&zoom=1.5&x=-8&y=188" target="_blank" style={{ textDecoration: 'none', color: '#FFFFFF' }}>-8,188</a> once launched.
                   <s.SpacerSmall />
                   The dAppy Bears will be available for minting until sold out. 
                   <s.SpacerSmall />
                   
                   After you mint your dAppy Bear it will be viewable on OpenSea.io using your connected wallet. 
                   <s.SpacerSmall />
                   <strong>The dAppyBears is our genesis NFT Collection and to reward you for being a holder you will be added to the whitelist for our next NFT Collection.</strong>
                   <s.SpacerSmall />
                   <strong>You also can use your #dappybears NFT for commercial purposes such as putting it on merch to sell for profits. You own the intellectual property rights for the dAppyBears you own.</strong>
                   <s.SpacerSmall />
                   Stay updated and join the community on Twitter.
                   <s.SpacerSmall />
                   <a href="https://twitter.com/dappybears" target="_blank"><StyledTwitter alt={"twitter"} src={"/config/images/twitter.png"} /></a> <a href="https://opensea.io/collection/dappybears" target="_blank"><StyledTwitter alt={"twitter"} src={"/config/images/opensea.png"} /></a>  
               </s.TextDescription>
               
                  
            </s.Container>

      
    </ResponsiveWrapper>
    <ResponsiveWrapper flex={1} style={{ padding: 10 }}>
               <s.Container flex={1} jc={"center"} ai={"center"}>
               <s.TextTitle
                  style={{
                    textAlign: "left",
                    fontSize: 40,
                    fontWeight: "bold",
                    color: "var(--primary-text)",
                  }}
                >
                  dAppyBears Roadmap
                </s.TextTitle>
                <s.SpacerSmall />
                  <StyledRoadmap alt={"dAppyBears Roadmap"} src={"/config/images/roadmap.png"} />
                </s.Container>
                <s.SpacerSmall />
    </ResponsiveWrapper>

      
    <s.Container
        flex={1}
        ai={"center"}
        style={{ padding: 24, backgroundColor: "var(--primary)" }}
        image={CONFIG.SHOW_BACKGROUND ? "/config/images/bg.png" : null}
      >
      <ResponsiveWrapper flex={1} style={{ padding: 24 }}>
        
        <s.SpacerLarge />
        <s.Container
          flex={2}
          jc={"left"}
          ai={"center"}
          style={{
            padding: 24,
            color: "#ffffff",
          }}
        >

            <s.TextTitle
              style={{
                textAlign: "center",
                fontSize: 50,
                fontWeight: "bold",
                color: "var(--accent-text)",
              }}
            >
              The dAppy Bears Creator 
            </s.TextTitle>

            <s.Container flex={1} jc={"center"} ai={"center"}>
              <StyledImgTwo alt={"Founder"} src={"/config/images/rob-g-bw.jpg"} />
            </s.Container>
            <s.SpacerSmall />
            <s.Container flex={1} jc={"center"} ai={"center"}
              style={{
                fontWeight: "bold",
                padding: "1px",
                fontSize: 25,
                color: "var(--secondary-text)",
              }}>

              <a href="https://twitter.com/0xGottaMinute" style={{ textDecoration: 'none', color: '#FFFFFF' }}>@0xGottaMinute</a>

             
            </s.Container>
            <s.SpacerSmall />
            <s.Container flex={1} jc={"center"} ai={"center"}
              style={{
                padding: "1px",
                fontSize: 20,
                color: "var(--top-text)",
              }}>

             ROBERT GILLEN
            </s.Container>
            <s.SpacerSmall />

          <s.TextDescription
              style={{
                textAlign: "left",
                padding: "5px",
                color: "var(--top-text)",
                width: "50%",
              }}
            >
              Robert Gillen founder and artist at <a href="https://www.graphicsfactory.com" target="_blank" style={{ textDecoration: 'none', color: '#000000' }}>GraphicsFactory.com</a> has been creating and curating images online for the past 20 years. With his love for art and new technology he is excited to see where NFTs and crypto will take us in the future!
          </s.TextDescription>

        </s.Container>


        </ResponsiveWrapper>

  
        <s.SpacerSmall />
            <s.Container ai={"center"} jc={"center"} fd={"row"}>

              <s.TextDescription
                style={{
                  textAlign: "center",
                  color: "var(--main-text)",
                  padding: "3px",
                }}
              >
                <StyledLink target={"_blank"} href={CONFIG.SCAN_LINK} style={{ textDecoration: 'none', color: '#eeeeee' }}>
                  PolyScan: {truncate(CONFIG.CONTRACT_ADDRESS, 15)}

                  
                </StyledLink>
              </s.TextDescription>
              <s.SpacerSmall />
              
          
            </s.Container>
            <s.Container ai={"center"} jc={"center"} fd={"row"}>
              <s.TextDescription
              style={{
                textAlign: "left",
                padding: "5px",
                color: "var(--top-text)",
              }}
            >
             Created with ♥️ for the crypto community.
          </s.TextDescription>
            </s.Container>



        </s.Container>
        

    </s.Screen>
  );
}

export default App;
